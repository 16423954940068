<template>
    <div>
        <div class="vx-row">
            <div class="vx-col w-full mb-3">
                <ActionCard
                    @createMarketingTask="show.marketingModal = true"
                ></ActionCard>
            </div>
        </div>
        <div class="vx-row">
            <div class="vx-col w-full mb-3">
                <MarketingTasksCard
                    v-if="marketingTasks"
                    :data="marketingTasks"
                    @getPage="marketingTasksFetch"
                />
                <MarketingTaskModal
                    :show="show.marketingModal"
                    @close="show.marketingModal = false"
                    @refresh="marketingTasksFetch"
                />
            </div>
        </div>
        <div class="vx-col w-full mb-3">
            <SystemTasksCard
                v-if="systemTasks"
                :data="systemTasks.data"
                @getPage="systemTasksFetch"
            />
        </div>
    </div>
</template>

<script>
import { http } from '@/services'

import ActionCard from './Cards/Action'
import SystemTasksCard from './Cards/SystemTasks'
import MarketingTasksCard from './Cards/MarketingTasks'
import MarketingTaskModal from './Modals/MarketingTask'

export default {
    name: 'Tasks',
    props: [],
    components: {
        ActionCard,
        SystemTasksCard,
        MarketingTasksCard,
        MarketingTaskModal,
    },
    mounted() {
        this.systemTasksFetch()
        this.marketingTasksFetch()
    },
    data() {
        return {
            systemTasks: null,
            marketingTasks: null,
            show: {
                marketingModal: false,
            },
        }
    },
    methods: {
        systemTasksFetch(page = null) {
            var data = {
                params: {
                    page_number: page,
                },
            }
            http.get('system_tasks', data).then((response) => {
                this.systemTasks = response.data
            })
        },
        marketingTasksFetch(page = null) {
            var data = {
                params: {
                    page_number: page,
                },
            }
            http.get('marketing_tasks', data).then((response) => {
                this.marketingTasks = response.data
            })
        },
    },
}
</script>
