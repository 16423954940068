<template>
    <vx-card title="System Tasks" :subtitle="subTitle" collapse-action>
        <div class="flex flex-row-reverse"></div>
        <vs-table
            ref="table"
            v-model="selected"
            :data="data"
            class="mb-base"
            :sst="true"
        >
            <template slot="thead">
                <vs-th>Name</vs-th>
                <vs-th>Description</vs-th>
                <vs-th>When</vs-th>
                <vs-th>Enabled</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="index" v-for="(tr, index) in data">
                    <vs-td>{{ tr.name }}</vs-td>

                    <vs-td>{{ tr.description }}</vs-td>

                    <vs-td>
                        <span v-if="tr.crontab">
                            Time : {{ tr.crontab.hour }}h :
                            {{ tr.crontab.minute }}m
                            <br />
                            Day(s) of week : {{ day(tr.crontab.day_of_week) }}
                            <br />
                        </span>
                    </vs-td>

                    <vs-td>
                        <vs-chip v-if="tr.enabled" color="success"></vs-chip>
                        <vs-chip v-else color="secondary"></vs-chip>
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination
            v-if="data.number_of_pages > 1"
            :total="data.number_of_pages"
            v-model="pageNumber"
        ></vs-pagination>
    </vx-card>
</template>

<script>
import { textFormat } from '@/mixins/TextFormat'
import { tableHelpers } from '@/mixins/tableHelpers'
import { http } from '@/services'

export default {
    name: 'SystemTasksCard',
    props: ['data'],
    mixins: [textFormat, tableHelpers],
    components: {},
    watch: {
        pageNumber(value) {
            if (value) this.getPage()
        },
    },
    data() {
        return {
            pageNumber: 1,
            selected: null,
        }
    },
    computed: {
        subTitle() {
            if (this.data && this.data.length)
                return this.data.length + ' System Tasks'
            return 'No System Tasks'
        },
    },
    methods: {
        setPageNumber(value) {
            this.pageNumber = value
            this.getPage()
        },
        getPage() {
            this.$emit('getPage', this.pageNumber)
        },
        day(value) {
            var string = ''
            if (value.includes('*')) string += 'All '
            if (value.includes('1')) string += 'Monday '
            if (value.includes('2')) string += 'Tuesday '
            if (value.includes('3')) string += 'Wednesday '
            if (value.includes('4')) string += 'Thursday '
            if (value.includes('5')) string += 'Friday '
            if (value.includes('6')) string += 'Saturday '
            if (value.includes('7')) string += 'Sunday '
            if (string === '') return value
            return string
        },
    },
}
</script>

<style></style>
