<template>
    <vx-card title="Marketing Tasks" :subtitle="subTitle" collapse-action>
        <div class="flex flex-row-reverse"></div>
        <vs-table
            ref="table"
            v-model="selected"
            :data="data.data"
            class="mb-base"
            :sst="true"
        >
            <template slot="thead">
                <vs-th>Name</vs-th>
                <vs-th>Email</vs-th>
                <vs-th>To</vs-th>
                <vs-th>Date</vs-th>
                <vs-th>Emails Sent</vs-th>
                <vs-th></vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="index" v-for="(tr, index) in data">
                    <vs-td>{{ tr.name }}</vs-td>
                    <vs-td>{{ tr.email_template.description }}</vs-td>
                    <vs-td>{{ to(tr.to) }}</vs-td>
                    <vs-td>
                        <vs-chip
                            v-if="new Date(tr.date) > new Date()"
                            class="mr-3"
                            color="success"
                            >Up-coming</vs-chip
                        >
                        <vs-chip
                            v-else-if="new Date(tr.date) < new Date()"
                            class="mr-3"
                            color="secondary"
                            >Exprired</vs-chip
                        >
                        <span>{{ formatDateAndTime(tr.date) }}</span>
                    </vs-td>
                    <vs-td>
                        <span v-if="tr.log">{{ tr.log.emails_sent }}</span>
                    </vs-td>

                    <vs-td>
                        <vs-button
                            v-on:click="confirmDeleteTask(tr)"
                            radius
                            color="secondary"
                            type="flat"
                            icon-pack="feather"
                            icon="icon-trash"
                        />
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination
            v-if="data.number_of_pages > 1"
            :total="data.number_of_pages"
            v-model="pageNumber"
        ></vs-pagination>
    </vx-card>
</template>

<script>
import { textFormat } from '@/mixins/TextFormat'
import { tableHelpers } from '@/mixins/tableHelpers'
import { http } from '@/services'

export default {
    name: 'MarketingTasksCard',
    props: ['data'],
    mixins: [textFormat, tableHelpers],
    components: {},
    watch: {
        pageNumber(value) {
            if (value) this.getPage()
        },
    },
    data() {
        return {
            pageNumber: 1,
            selected: null,
        }
    },
    computed: {
        subTitle() {
            if (this.data.data && this.data.data.length)
                return this.data.data.length + ' Marketing Tasks'
            return 'No Marketing Tasks'
        },
    },
    methods: {
        setPageNumber(value) {
            this.pageNumber = value
            this.getPage()
        },
        getPage() {
            this.$emit('getPage', this.pageNumber)
        },
        to(value) {
            if (value === 'all') return 'All'
            if (value === 'without_wills') return 'Without Wills'
            if (value === 'with_wills') return 'With Wills'
            return value
        },
        confirmDeleteTask(tr) {
            this.selected = tr
            this.$vs.dialog({
                type: 'confirm',
                color: 'secondary',
                title: 'Delete Marketing Task',
                text: tr.name,
                accept: this.deleteTask,
                acceptText: 'Delete',
            })
        },
        deleteTask() {
            var postData = {
                params: {
                    id: this.selected.id,
                },
            }
            http.delete('task_marketing', postData)
                .then((response) => {
                    this.$emit('getPage')
                    this.$vs.notify({
                        title: 'Success',
                        text: 'Task Deleted',
                        icon: 'feather',
                        iconPack: 'icon-check',
                        color: 'primary',
                    })
                })
                .catch((error) => {
                    console.log(error.response.data)
                    this.$vs.notify({
                        title: 'Error',
                        text: 'Error deleteing task, check console',
                        icon: 'feather',
                        iconPack: 'icon-x',
                        color: 'primary',
                    })
                })
        },
    },
}
</script>

<style></style>
