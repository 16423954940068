export const tableHelpers = {
    methods: {
        timeDiffCat(startTime, category = null) {
            if (!startTime) return ['None', 'success']

            let x = new Date(startTime)
            let now = new Date()

            var timeDiff = Math.abs(now - x)
            timeDiff /= 1000

            var seconds = Math.round(timeDiff)
            timeDiff = Math.floor(timeDiff / 60)

            var minutes = Math.round(timeDiff % 60)
            timeDiff = Math.floor(timeDiff / 60)

            var hours = Math.round(timeDiff % 24)
            timeDiff = Math.floor(timeDiff / 24)

            var days = Math.round(timeDiff % 365)
            timeDiff = Math.floor(timeDiff / 365)

            var years = timeDiff

            var dangerDays = 0
            var dangerHours = 0

            switch (category) {
                case 'approve':
                    dangerDays = 1
                    dangerHours = 47
                    break
                case 'contact':
                    dangerDays = 4
                    dangerHours = 95
                    break
                case 'check':
                    dangerDays = 1
                    dangerHours = 47
                    break
                case 'print':
                    dangerDays = 0
                    dangerHours = 23
                    break
                default:
                    dangerDays = 0
                    dangerHours = 0
                    break
            }

            if (years > 0) {
                return [
                    years + (years > 1 ? ' Years ' : ' Year ') + 'ago',
                    'danger',
                ]
            } else if (days > dangerDays) {
                return [
                    days + (days > 1 ? ' Days ' : ' Day ') + 'ago',
                    'danger',
                ]
            } else if (days > 0) {
                return [
                    days + (days > 1 ? ' Days ' : ' Day ') + 'ago',
                    'success',
                ]
            } else if (hours > dangerHours) {
                return [
                    hours + (hours > 1 ? ' Hours ' : ' Hour ') + 'ago',
                    'danger',
                ]
            } else if (hours > 0) {
                return [
                    hours + (hours > 1 ? ' Hours ' : ' Hour ') + 'ago',
                    'success',
                ]
            } else if (minutes > 0) {
                return [
                    minutes + (minutes > 1 ? ' Minutes ' : ' Min ') + 'ago',
                    'success',
                ]
            } else if (seconds > 0) {
                return [
                    seconds + (seconds > 1 ? ' Seconds ago' : 'just now'),
                    'success',
                ]
            }

            return ['Just Now', 'success']
        },
        timeColour(startTime, category) {
            return this.timeDiffCat(startTime, category)[1]
        },
        timeDifference(startTime, category = null) {
            return this.timeDiffCat(startTime, category)[0]
        },
        introducer(value) {
            if (value) {
                if (value.broker) return value.broker.company_name
                if (value.charity) return value.charity.charity_name
            }
            return null
        },
        couponUsed(value) {
            if (value) return value.code
            return null
        },
    },
}
