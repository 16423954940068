var moment = require('moment')

export const textFormat = {
    methods: {
        formatDateAndTime(value) {
            if (value) {
                return moment(String(value)).format('DD/MM/YYYY HH:mm')
            }
        },
        formatDate(value) {
            if (value) {
                return moment(String(value)).format('DD/MM/YYYY')
            }
        },
        formatDateShort(value) {
            if (value) {
                return moment(String(value)).format('DD/MM/YY')
            }
        },
        formatDateLong(value) {
            if (value) {
                return moment(String(value)).format('HH:mm ddd Do MMM ')
            }
        },
        formatDateDescription(value) {
            if (value) {
                return moment(String(value)).format('Do MMMM')
            }
        },
        formatTime(value) {
            if (value) {
                return moment(String(value)).format('HH:mm')
            }
        },
        formatDayOnly(value) {
            if (value) {
                return moment(String(value)).format('D')
            }
        },
        formatDateAlternative(value) {
            if (value) {
                return moment(String(value)).format('YYYY-MM-DD')
            }
        },
        getTime() {
            return moment().format('h:mm')
        },
        age(date) {
            console.log(date)
            return moment().diff(date, 'year')
        },
        truncate(str, length, ending) {
            if (!str) return null
            if (length == null) {
                length = 50
            }
            if (ending == null) {
                ending = '...'
            }
            if (str.length > length) {
                return str.substring(0, length - ending.length) + ending
            } else {
                return str
            }
        },
        formatUser(value) {
            return value.split('@')[0]
        },
        amountPaid(value) {
            var string = ''
            if (value.update) string += 'Update - '
            string += 'Paid £' + value.paid_amount / 100
            return string
        },
        capitalise(value) {
            return value[0].toUpperCase() + value.substr(1)
        },
        firstWord(string, plural = false, span = true) {
            var name = String(string).split(' ')[0]
            if (plural) name += "'s"
            if (span) return this.span(name)
            return name
        },
        currency(value) {
            var formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'GBP',
            })

            return formatter.format(value)
        },
    },
}
