<template>
    <vs-popup
        class="showOverflow"
        @cancel="close"
        @accept="save"
        @close="close"
        :title="title"
        :active.sync="showModal"
    >
        <VuePerfectScrollbar
            class="scroll-area p-4 showOverflow"
            :settings="settings"
            :key="$vs.rtl"
        >
            <div
                id="loading-container"
                class="vs-con-loading__container showOverflow"
            >
                <vs-input
                    v-model="taskData.name"
                    type="text"
                    placeholder="Name"
                    label="Task Name"
                    class="mb-3 w-full"
                />
                <div class="mb-3">
                    <label for class="vs-input--label">
                        <span>Users</span>
                    </label>
                    <v-select
                        v-model="setTo"
                        :options="to"
                        placeholder="Users"
                    ></v-select>
                </div>
                <vs-divider> Or </vs-divider>
                <div class="mb-3">
                    <label for class="vs-input--label">
                        <span>Brokers</span>
                    </label>
                    <v-select
                        v-model="setToBroker"
                        :options="to_broker"
                        placeholder="Brokers"
                    ></v-select>
                </div>

                <EmailTemplateSelect @selectedTemplate="setEmailTemplate" />

                <div class="mb-3">
                    <vs-input
                        label="Date & Time to send"
                        type="datetime-local"
                        v-model="taskData.date"
                        class="w-full"
                    />
                </div>
            </div>
        </VuePerfectScrollbar>
        <vs-divider></vs-divider>
        <vs-button @click="save" :disabled="!validateForm" class="mr-3"
            >Create</vs-button
        >
        <vs-button @click="close" type="flat">Cancel</vs-button>
    </vs-popup>
</template>

<script>
import { http } from '@/services'
import { functions } from '@/mixins/functions'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import vSelect from 'vue-select'
import EmailTemplateSelect from '@/components/common/EmailTemplateSelect'

export default {
    name: 'MarketingTaskModal',
    props: ['show', 'user'],
    mixins: [functions],
    components: {
        VuePerfectScrollbar,
        vSelect,
        EmailTemplateSelect,
    },
    watch: {
        user: {
            immediate: true,
            handler(value) {
                if (value) {
                    this.accountData.id = value.id
                    this.accountData.email = value.email
                }
            },
        },
    },
    computed: {
        title() {
            return 'Create One Off Marketing Task'
        },
        showModal: {
            get() {
                return this.show
            },
            set(value) {
                // this.$emit('show', value)
            },
        },
        setTo: {
            set(value) {
                if (value) this.taskData.to = value.value
                else this.taskData.to = null
            },
            get() {
                return this.to.find((ob) => ob.value === this.taskData.to)
            },
        },
        setToBroker: {
            set(value) {
                if (value) this.taskData.to_broker = value.value
                else this.taskData.to_broker = null
            },
            get() {
                return this.to_broker.find(
                    (ob) => ob.value === this.taskData.to_broker
                )
            },
        },
        validateForm() {
            if (
                this.taskData.name &&
                this.taskData.date &&
                (this.taskData.to || this.taskData.to_broker) &&
                !(this.taskData.to && this.taskData.to_broker) &&
                this.taskData.email
            )
                return true

            return false
        },
    },
    data() {
        return {
            settings: {
                maxScrollbarLength: 60,
                wheelSpeed: 0.3,
            },
            taskData: {
                name: '',
                date: null,
                to: null,
                to_broker: null,
                email: null,
                enabled: true,
            },
            errors: {},
            to: [
                { label: 'All', value: 'all' },
                { label: 'With wills', value: 'with_wills' },
                { label: 'Without wills', value: 'without_wills' },
            ],
            to_broker: [{ label: 'All', value: 'all' }],
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        clearErrors() {},
        clearForm() {},
        setEmailTemplate(template) {
            this.taskData.email = template.value
        },
        setSmsTemplate(template) {
            this.taskData.sms = template.value
        },
        save() {
            this.clearErrors()
            this.$vs.loading({
                background: this.backgroundLoading,
                color: this.colorLoading,
                container: '#loading-container',
                scale: 0.45,
            })

            http.post('task_marketing', this.taskData)
                .then((response) => {
                    this.$emit('close')
                    this.$vs.loading.close(
                        '#loading-container > .con-vs-loading'
                    )
                    this.$emit('refresh')
                    this.$vs.notify({
                        title: 'Success',
                        text: 'Task Created',
                        icon: 'feather',
                        iconPack: 'icon-check',
                        color: 'primary',
                    })
                })
                .catch((error) => {
                    if (error.response.data.email)
                        this.errors.email = error.response.data.email[0]
                    else this.error.email = null
                    this.$vs.loading.close(
                        '#loading-container > .con-vs-loading'
                    )
                })
        },
    },
}
</script>
