<template>
    <div class="w-full">
        <label for="" class="vs-input--label"
            ><span v-if="selected">Email</span></label
        >
        <v-select
            v-model="selected"
            class="w-full"
            :options="templates"
            placeholder="Select Email"
            @search="fetchTemplates"
        />
    </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { http } from '@/services'

export default {
    name: 'EmailTemplateSelect',
    components: {
        vSelect,
    },
    props: ['emailTemplate'],
    data() {
        return {
            templates: [],
            selected: '',
        }
    },
    watch: {
        selected(value) {
            this.$emit('selectedTemplate', value)
        },
    },
    mounted() {
        this.fetchTemplates()
    },
    methods: {
        fetchTemplates(search, loading) {
            if (search) {
                http.get('system_email_widget/' + search)
                    .then((response) => {
                        this.templates = response.data
                        if (this.emailTemplate)
                            this.selected = this.plans.filter(
                                (template) => template.id === this.emailTemplate
                            )[0]
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        },
    },
}
</script>

<style></style>
