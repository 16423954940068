<template>
    <div>
        <div class="flex flex-row-reverse w-full">
            <vs-dropdown
                vs-trigger-click
                class="cursor-pointer mb-4 mr-4 items-per-page-handler"
            >
                <div
                    class="p-4 border border-solid rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                >
                    <span class="mr-2"> Action </span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>

                <vs-dropdown-menu
                    class="w-full sm:w-full md:w-1/3 lg:w-1/3 xl:w-1/6"
                >
                    <vs-dropdown-item v-on:click="$emit('createMarketingTask')">
                        Create Marketing Task
                    </vs-dropdown-item>
                </vs-dropdown-menu>
            </vs-dropdown>
        </div>
    </div>
</template>

<script>
import { textFormat } from '@/mixins/TextFormat'
import { http } from '@/services'

export default {
    name: 'ActionCard',
    props: ['data'],
    mixins: [textFormat],
    components: {},
    data() {
        return {
            show: {
                deleteModal: false,
                confirmModal: false,
                usernamePasswordModal: false,
            },
            confirm: {
                title: null,
                action: null,
            },
        }
    },
    methods: {
        deleteUser() {
            http.delete('user_detail', { params: { id: this.data.id } })
                .then((response) => {
                    this.show.deleteModal = false
                })
                .then((response) => {
                    this.$vs.notify({
                        title: 'User Deleted',
                        text: this.data.details.full_name || '',
                        color: 'success',
                    })
                    this.$router.push('/users/all')
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        sendPasswordResetEmail() {
            http.post('user_send_password_reset', {
                user_id: this.data.id,
            }).then((response) => {
                this.$vs.notify({
                    title: 'Success',
                    text: `Password reset sent to ${this.data.email}`,
                    color: 'success',
                })
            })
        },
        confirmPasswordResetEmail() {
            this.$vs.dialog({
                type: 'confirm',
                color: 'success',
                title: `Send Password Reset Email`,
                text: ` ${this.data.email}`,
                accept: this.sendPasswordResetEmail,
            })
        },
        confirmDeleteUser() {
            this.$vs.dialog({
                type: 'confirm',
                color: 'secondary',
                title: `Delete User`,
                text: ` ${this.data.email}`,
                accept: this.deleteUser,
            })
        },
    },
}
</script>

<style></style>
